import React from "react";
import classNames from "classnames";
import { Link, graphql, useStaticQuery } from "gatsby";
import { gaEvent } from "../../helpers";
import { useModal } from "../../../provider";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Heading from "../Heading";

const heroHeaderQuery = graphql`
  query {
    image: file(relativePath: { eq: "generic-sydney-outdoor-cover.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default function HeroHeader({
  title,
  svgLogo,
  logoTagline,
  background_image,
  options,
  hideShading,
  className,
}) {
  const headerImage = useStaticQuery(heroHeaderQuery);
  // const defaultBackgroundImage = "/img/generic-sydney-outdoor-cover.jpg";
  const backgroundImage = background_image || headerImage?.image;
  const modal = useModal();

  const defaultOptions = [
    { title: "New to FEAT Fitness?" },
    { title: "Membership Options" },
    {
      title: "Free Trial",
      highlighted: true,
      onClick: () => {
        gaEvent("Button", "FreeTrial", "free trial - hero header");
        modal.openFreeTrialModal();
      },
    },
  ];
  const menuOptions = options || defaultOptions;
  return (
    <div className={classNames("relative", className)}>
      <div className="mx-auto">
        <div className="relative shadow-xl sm:overflow-hidden">
          <div className="absolute inset-0">
            <GatsbyImage
              className="h-full w-full object-cover object-center	"
              image={getImage(backgroundImage)}
              alt="FEAT Fitness Background"
            />

            {!hideShading && (
              <div className="absolute inset-0 bg-gradient-to-t from-gray-100 to-gray-400 mix-blend-multiply" />
            )}
          </div>
          <div className="relative lg:mx-auto px-4 py-16 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-3 lg:gap-8 sm:py-24 lg:py-48">
            {menuOptions.length > 0 ? (
              <ul className="bg-white shadow uppercase divide-y divide-feat-lightgrey-500 font-heading font-semibold text-feat-darkgray">
                <li className="py-4 pt-8 pl-4 mx-4 flex text-3xl font-bold text-feat-blue ">
                  <h1>{title}</h1>
                </li>
                {menuOptions.map((option) => {
                  if (option.to) {
                    return (
                      <Link
                        className={classNames(
                          option?.highlighted
                            ? "px-8 bg-feat-blue text-white"
                            : "pl-4 mx-4",
                          "py-4 flex"
                        )}
                        to={option.to}
                        key={option.title}
                      >
                        {option.title}
                      </Link>
                    );
                  } else {
                    return (
                      <li
                        className={classNames(
                          option?.highlighted
                            ? "px-8 bg-feat-blue text-white"
                            : "pl-4 mx-4",
                          "py-4 flex",
                          {
                            "cursor-pointer": option.onClick,
                          }
                        )}
                        onClick={option.onClick}
                        key={option.title}
                      >
                        {option.title}
                      </li>
                    );
                  }
                })}
              </ul>
            ) : svgLogo ? (
              <>
                <div className="h-full object-contain object-center text-center">
                  <img src={svgLogo} alt={title} className="mb-8" />
                  {logoTagline && (
                    <Heading
                      level={1}
                      color={"white"}
                      size={"2xl"}
                      className="font-light"
                    >
                      {logoTagline}
                    </Heading>
                  )}
                </div>
              </>
            ) : (
              title &&
              title !== "" && (
                <h1 className="lg:col-span-3 uppercase font-heading font-semibold text-center py-4 px-4 mx-4 flex text-5xl text-white drop-shadow">
                  {title}
                </h1>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

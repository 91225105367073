import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import classNames from "classnames";

const imageClassNames =
  "absolute-important w-full h-40 object-cover rounded-full left-0 top-0 bottom-0 right-0 xl:w-56 xl:h-56 ";

const HoverImage = ({ front_image, hover_image, img_title }) => {
  if (front_image && hover_image) {
    return (
      <div //OverlayContentContainer
        className="image-hover relative m-auto overflow-hidden hover:opacity-100 h-40 w-40 xl:w-56 xl:h-56 rounded-full group-hover:shadow-xl "
      >
        <GatsbyImage // OverlayImg
          className={classNames(
            imageClassNames,
            "z-10 opacity-0 hover:opacity-100 transition-all ease-in-out duration-500	"
          )}
          image={getImage(hover_image)}
          alt={img_title}
          style={{ position: "absolute !important" }}
        />
        <GatsbyImage // MainImg
          className={imageClassNames}
          image={getImage(front_image)}
          alt={img_title}
          style={{ position: "absolute !important" }}
        />
      </div>
    );
  }
  return <>No Image</>;
};

export default HoverImage;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../components/layout";
import HeroHeader from "../../components/HeroHeader";
import Trainers from "../../components/Trainers";
import Jobs from "../../components/Jobs";
import Heading from "../../components/Heading";

const meetOurTrainersId = "meet-our-trainers";
const becomeATrainerId = "become-a-trainer";

const heroHeaderQuery = graphql`
  query {
    image: file(relativePath: { eq: "about-us-banner.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`;

export default function OurTrainersPage() {
  const headerImage = useStaticQuery(heroHeaderQuery);
  return (
    <Layout>
      <Seo
        title="Our Trainers | FEAT Fitness"
        meta_title="Our Trainers | FEAT Fitness"
        meta_desc="FEAT Fitness has an amazing group of energetic and motivating trainers. Check out the people that get so many people around your local area up and moving together. FEAT Fitness is fun community based training in your local outdoor spaces."
        slug="our-trainers"
      />
      <main>
        <HeroHeader
          title="Our FEAT Trainers"
          background_image={headerImage?.image}
          options={[
            { title: "Meet our Trainers", to: `#${meetOurTrainersId}` },
            { title: "Become a FEAT Trainer", to: `#${becomeATrainerId}` },
          ]}
        />
        <div className="my-16 text-center" id={meetOurTrainersId}>
          <Heading id="meet-our-trainers" level={2} className="mb-16">
            Meet our Trainers
          </Heading>
          <Trainers />
        </div>
        <Jobs id={becomeATrainerId} className="bg-feat-lightgrey" />
      </main>
    </Layout>
  );
}

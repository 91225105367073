import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import TrainerIcon from "../TrainerIcon";

export default function Trainers({ excludeIds }) {
    const trainer_data = useStaticQuery(graphql`
        query {
            allSanityTrainer(
                filter: { slug: { current: { ne: null } } }
                sort: { order: ASC, fields: [order] }
            ) {
                edges {
                    node {
                        title
                        slug {
                            current
                        }
                        locations {
                            location {
                                slug {
                                    current
                                }
                            }
                        }
                        funnyPhoto {
                            asset {
                                gatsbyImageData(
                                    fit: FILLMAX
                                    placeholder: BLURRED
                                )
                            }
                        }
                        smilePhoto {
                            asset {
                                gatsbyImageData(
                                    fit: FILLMAX
                                    placeholder: BLURRED
                                )
                            }
                        }
                        _rawBody(resolveReferences: { maxDepth: 5 })
                    }
                }
            }
        }
    `);

    const trainers = trainer_data.allSanityTrainer.edges;

    return (
        <ul className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:grid-cols-3 lg:max-w-5xl">
            {trainers.map(({ node: trainer }) => {
                if (excludeIds && excludeIds.includes(trainer.id)) return null;
                return (
                    <li key={trainer.id}>
                        <TrainerIcon trainer={trainer} />
                    </li>
                );
            })}
        </ul>
    );
}

import React from "react";
import { Link } from "gatsby";

export default function JobButton({ job }) {
  return (
    <div className="group relative border rounded max-w-sm w-full text-center border-feat-darkgray-200 items-center px-2.5 py-1.5 shadow-sm text-xs font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-feat-blue-500">
      <div className="my-4">
        <h3 className="text-lg font-heading text-feat-darkgray-500 uppercase">
          <Link to={`/jobs/${job.slug.current}`}>
            <span aria-hidden="true" className="absolute inset-0" />
            {job.title}
          </Link>
        </h3>
        <p className="mt-1 text-sm text-darkgray-400">{job.subtitle}</p>
        <p className="text-sm font-medium text-gray-900">
          {job.employmentType}
        </p>
        <p className="text-sm font-semibold uppercase text-feat-darkgray-500 mt-1">
          Read More & Apply
        </p>
      </div>
    </div>
  );
}

import classNames from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import JobButton from "./JobButton";

export default function Jobs({ id, className }) {
  const jobsData = useStaticQuery(graphql`
    query {
      allSanityJob(
        filter: { slug: { current: { ne: null } } }
        sort: { order: ASC, fields: [title] }
      ) {
        edges {
          node {
            id
            title
            subtitle
            employmentType
            publishedAt
            slug {
              current
            }
          }
        }
      }
    }
  `);
  const jobs = jobsData.allSanityJob.edges;

  return (
    <div
      className={classNames(
        className,
        "py-12 md:py-6 px-6 sm:px-32 text-feat-darkgray text-center"
      )}
      id={id}
    >
      <h2 className="text-heading uppercase text-2xl sm:text-3xl text-feat-darkgray text-center mb-2 sm:mb-8">
        Join the FEAT Team
      </h2>
      <div className="max-w-2xl text-light text-base mx-auto">
        <p className="mt-6">
          Want to join a high class team of trainers that love to be outdoors
          and help people achieve their goals?
        </p>
        <p className="mt-6">
          FEAT Fitness is an outdoor group fitness community and the team is
          currently expanding. At FEAT our Trainers specialise in delivering
          outdoor group training sessions whilst still delivering a personalised
          approach to training.
        </p>
        <p className="mt-6">
          Founded four years ago, FEAT has touched hundreds of lives and helped
          people improve their overall health and fitness and been the
          connection point for many new friendships within local communities.
        </p>
        <p className="mt-6">
          At FEAT Fitness, we offer outdoor group training in local parks around
          Sydney and we are fast expanding with the demand to be outdoors. To
          support the growth of FEAT, we are looking for passionate, committed
          trainers to join the FEAT Fitness trainer team.
        </p>
        <p className="mt-6">
          We are currently hiring two different roles and expanding the FEAT
          team.
        </p>
      </div>
      <div className="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-10 gap-x-6 justify-items-center">
          {jobs.map(({ node: job }) => (
            <JobButton job={job} />
          ))}
        </div>
      </div>
    </div>
  );
}

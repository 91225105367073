import React from "react";
import { useModal } from "../../../provider";
import HoverImage from "../HoverImage";

export default function TrainerIcon({ trainer }) {
    const modal = useModal();
    return (
        <div
            role="button"
            tabIndex={0}
            onClick={() => modal.openTrainerModalOpen(trainer)}
            onKeyDown={(ev) => {
                if (ev.keyCode === 13) {
                    modal.openTrainerModalOpen(trainer);
                }
            }}
            className="space-y-6 group cursor-pointer"
        >
            <HoverImage
                front_image={trainer.funnyPhoto.asset}
                hover_image={trainer.smilePhoto.asset}
                img_title={trainer.title}
            />

            <div className="space-y-2">
                <div className="text-lg leading-6 font-medium space-y-1">
                    <div className="uppercase group-hover text-feat-darkgray-700 ">
                        {trainer.title}
                    </div>
                </div>
            </div>
        </div>
    );
}
